import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "../../../hooks";
import { ImgCard, SpanCartNew, SpanCartDiscount, SpanCartStanding, StyledLink, UserProfil } from '../../../styles/atoms';
import ReadMoreDescription from "../widgets/readMoreDescription";

function CardAds({ id, ad, name, user_photo_url, user_first_name, category, designation, type, town, street, cover, price, view, free_booking, is_new, reff, standing }) {
  const { theme } = useTheme();
  const [coupon7, setCoupon7] = useState(0)
  const [coupon30, setCoupon30] = useState(0)
  const [discount, setDiscount] = useState(0)

  useEffect(() => {
    price.weeks > 0 && setCoupon7(Math.round(((price.days - (price.weeks/7))/price.days)*100))
    price.months > 0 && setCoupon30(Math.round(((price.days - (price.months/30))/price.days)*100))
  }, [price] )

  useEffect(() => {
    coupon7 > coupon30 ? setDiscount(coupon7) : setDiscount(coupon30)
  }, [coupon7, coupon30] )

  // Préparation des données en dehors du JSX pour éviter les calculs dans le render
  const formattedDesignation = designation
    ? designation.split(' ').join('_').split('&').join('_').split('/').join('_')
    : '';
  const propertyLink = `/${category}/property/${formattedDesignation}/${id}`;
  const facebookShareUrl = encodeURIComponent(`https://www.book-easily.com${propertyLink}`);
  const whatsappMessage = encodeURIComponent(`${type} - ${category} de ${formattedDesignation} à ${town} - ${street} disponible sur BookEasily. Consultez ici: https://www.book-easily.com${propertyLink}`);

  // Fallback image
  const handleImageError = (e) => {
    e.target.src = "https://api.book-easily.com/storage/assets/NoImage.jpg"; // Mettre une image par défaut
  };

  return (
    <div className="col p-1" key={reff}>
      <div className="card rounded-4">
        <div className="position-relative">
          <StyledLink theme={theme} to={propertyLink}>
            <ImgCard
              srcSet={`${cover[0]}`}
              src={cover[0]}
              loading="lazy"
              className="card-img img-fluid"
              alt={`${name}-${street}`}
              onError={handleImageError}
            />
          </StyledLink>

          {discount > 0 && <SpanCartDiscount><i class="bi bi-tags-fill"></i> - { discount } %</SpanCartDiscount>}
          
          {is_new === 1 && <SpanCartNew>Nouveau 🎊</SpanCartNew>}
          
          {standing && (
            <SpanCartStanding>
              {'⭐'.repeat(standing)}
            </SpanCartStanding>
          )}
        </div>

        <div className="card-body pb-3">
          <StyledLink to={propertyLink} className="row justify-content-between">
            <h4 className="col-9 text-capitalize" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '0.9rem' }	}>
              <UserProfil src={ `https://api.book-easily.com/${user_photo_url}` } alt={user_first_name} /> {type} - {category}
            </h4>
            <div className="col-3 text-end">
              <i className="bi bi-eye"></i> {view}
            </div>
          </StyledLink>
          <em>
            {category === 'hôtels' && `${price.days} XAF / Nuitée`}
            {category === 'meublés' && `${price.days} XAF / Nuitée`}
            {category === 'non meublés' && `${price.months} XAF / Mois`}
          </em>
          <ReadMoreDescription text={name} maxLength={30} />
          <div className="row justify-content-between g-1">
            <div className="col-9">
              <p className="text-capitalize" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '0.9rem' }	}>
                <i className="bi bi-geo-alt-fill text-danger"></i> {town} - {street}
              </p>
            </div>
            <div className="col-3">
              <div className="row text-end">
                <Link className="col" to={`https://api.whatsapp.com/send?text=${whatsappMessage}`} target="_blank">
                  <i className="bi bi-whatsapp text-success"></i>
                </Link>
                <div className="col fb-share-button" data-href={`https://www.book-easily.com${propertyLink}`} data-layout="button" data-size="small">
                  <Link target="_blank" to={`https://www.facebook.com/sharer/sharer.php?u=${facebookShareUrl}`} className="fb-xfbml-parse-ignore">
                    <i className="bi bi-facebook"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAds;
