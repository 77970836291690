import paiement from '../../../assets/paiement.svg'
import safety from '../../../assets/safety.svg'
import support from '../../../assets/support.svg'
import community from '../../../assets/community.svg'

function Badge() {

    return (
        <div className="container pt-5">
            <div className="row row-cols-2 row-cols-md-4 g-3">
                <div className='col rounded-4 shadow p-3'>
                    <img src={paiement} width={30} alt="" />
                    <h4 className='fw-bold my-3'>Paiements sécurisés</h4>
                    <p></p>
                </div>
                <div className='col rounded-4 shadow p-3'>
                    <img src={support} width={30} alt="" />
                    <h4 className='fw-bold my-3'>Service client 7j/7</h4>
                    <p></p>
                </div>
                <div className='col rounded-4 shadow p-3'>
                    <img src={community} width={30} alt="" />
                    <h4 className='fw-bold my-3'>Large communauté</h4>
                    <p></p>
                </div>
                <div className='col rounded-4 shadow p-3'>
                    <img src={safety} width={30} alt="" />
                    <h4 className='fw-bold my-3'>Plateforme sécurisée</h4>
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export default Badge