import React, { useEffect, useState, useCallback } from "react";
import styled from 'styled-components';
import Error from "../widgets/error";
import Loading from "../widgets/loading";
import { useFetch44 } from "../../../hooks";
import { Button } from "../widgets/styles/stylesFilterButton";

const GridSearchHome = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;    
    align-items: center;
`;

function Filter({ countAds }) {
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
    const x_xsrf_token = localStorage.getItem("cookies");

    const [priceMax, setPriceMax] = useState();
    const [priceMin, setPriceMin] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [town, setTown] = useState("");
    const [streets, setStreets] = useState([]);
    const [categorie, setCategorie] = useState("");
    const [types, setTypes] = useState([]);
    const [nbPersons, setNbPersons] = useState();

    const { isLoading44, data41, data42, data43, data44, error44 } = useFetch44(
        `https://api.book-easily.com/api/ads/price/get_max_price`,
        `https://api.book-easily.com/api/towns`,
        `https://api.book-easily.com/api/categories`,
        `https://api.book-easily.com/api/ads/price/get_min_price`,
        token
    );

    const max = Math.round(data41?.data / 30);
    const towns = data42?.data || [];
    const categories = data43?.data || [];
    const min = data44?.data;

    useEffect(() => { setPriceMax(max); }, [max]);
    useEffect(() => { setPriceMin(min); }, [min]);

    const step = Math.round((max - min) / countAds);

    const fetchData = useCallback(async (url, setData, setId, id, token, x_xsrf_token) => {
        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                cache: "default",
                credentials: "include",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'X-XSRF-TOKEN': x_xsrf_token
                }
            });
            const data = await response.json();
            if (data?.status === 1) {
                setData(data?.data);
                setId(id);
            }
        } catch (err) {
            console.error(err.message);
        }
    }, []);

    const handleStreets = useCallback((e) => {
        fetchData(
            `https://api.book-easily.com/api/towns/${e.target.value}/streets`,
            setStreets,
            setTown,
            e.target.value,
            token,
            x_xsrf_token
        );
    }, [fetchData, token, x_xsrf_token]);

    const handleTypes = useCallback((e) => {
        fetchData(
            `https://api.book-easily.com/api/categories/${e.target.value}/types`,
            setTypes,
            setCategorie,
            e.target.value,
            token,
            x_xsrf_token
        );
    }, [fetchData, token, x_xsrf_token]);

    if (error44) {
        return <Error />;
    }

    return isLoading44 ? (
        <Loading />
    ) : (
        <>
        <Button type='button' data-bs-toggle="modal" data-bs-target="#searchModal">
            <i className="bi bi-search"></i>
        </Button>
        <div class="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5 fw-bold" id="searchModalLabel">Trouvez un logement <i className="bi bi-search" aria-hidden="true"></i></h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                            <form action="/results/q=" className="p-2" method="GET">
                                <GridSearchHome>
                                    <div className="col-12 my-2">
                                        <label htmlFor="town" className="form-label">Villes</label>
                                        <select className="form-select rounded-4" id="town" name="town" onChange={handleStreets}>
                                            <option value="">Ville...</option>
                                            {towns.map(town => (
                                                <option key={`${town.town}-${town.id}`} value={town.id}>{town.town}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {town && (
                                        <div className="col-12 my-2">
                                            <label htmlFor="street" className="form-label">Quartier</label>
                                            <select className="form-select rounded-4" id="street" name="street">
                                                <option value=''>Quartier...</option>
                                                {streets.map(street => (
                                                    <option key={`${street.name}-${street.id}`} value={street.id}>{street.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    <div className="col-12 my-2">
                                        <label htmlFor="category" className="form-label">Categories</label>
                                        <select className="form-select rounded-4" id="category" name="category" onChange={handleTypes}>
                                            <option value="">Categorie...</option>
                                            {categories.map(categorie => (
                                                <option key={`${categorie.name}-${categorie.id}`} value={categorie.id}>{categorie.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {categorie && (
                                        <div className="col-12 my-2">
                                            <label htmlFor="type" className="form-label">Type de logement</label>
                                            <select className="form-select rounded-4" id="type" name="type">
                                                <option value=''>Type de logement...</option>
                                                {types.map(type => (
                                                    <option key={`${type.name}-${type.id}`} value={type.id}>{type.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    <div className="col my-2">
                                        <label htmlFor="price_min" className="form-label text-muted">
                                            Budget minimum : <span className="text-warning">{priceMin} XAF</span>
                                        </label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            value={priceMin}
                                            onChange={(e) => setPriceMin(e.target.value)}
                                            min={min}
                                            max={priceMax}
                                            name="price_min"
                                            step={step}
                                            id="price_min"
                                        />
                                    </div>
                                    <div className="col my-2">
                                        <label htmlFor="price_max" className="form-label text-muted">
                                            Budget maximum : <span className="text-warning">{priceMax} XAF</span>
                                        </label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            value={priceMax}
                                            onChange={(e) => setPriceMax(e.target.value)}
                                            min={priceMin}
                                            max={max}
                                            step={step}
                                            id="price_max"
                                        />
                                    </div>
                                    <div className="col-12 my-2">
                                        <label htmlFor="start_date" className="form-label">Date d'arrivée</label>
                                        <div className="input-group">
                                            <div className="input-group-text">Du</div>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="start_date"
                                                max={endDate}
                                                value={startDate}
                                                name="start_date"
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <label htmlFor="end_date" className="form-label">Date de départ</label>
                                        <div className="input-group">
                                            <div className="input-group-text">Au</div>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="end_date"
                                                min={startDate}
                                                name="end_date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="number_persons" className="form-label">Nombre de personnes</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control rounded-4"
                                                id="number_persons"
                                                placeholder="Nombre de personnes..."
                                                name="number_persons"
                                                value={nbPersons}
                                                onChange={(e) => setNbPersons(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </GridSearchHome>
                                
                                <div className="col-12 mt-3 mb-2">
                                    <button
                                        type="submit"
                                        className="btn w-100 btn-outline-warning me-2 fw-bold rounded-4"
                                    >
                                        Chercher <i className="bi bi-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Filter;
