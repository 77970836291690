import React from 'react';
import I1 from "../../../assets/2.jpg";
import I2 from "../../../assets/12.jpg";
import I6 from "../../../assets/50.jpg";
import I3 from "../../../assets/22.jpg";
import { CatLink } from "../../../styles/atoms";

function Explore () {

    const images = [
        {
            id: '1',
            title: "Proche d'un site touristique",
            img: I1,
            link: `/results/q=?near_tourist_area=1`
        },
        {
            id: '2',
            title: "Fêtes autorisées",
            img: I6,
            link: `/results/q=?have_party=1`
        },
        {
            id: '3',
            title: "Réservation gratuite", 
            img: I3,
            link: `/results/q=?free_booking=1`
          },
        {
            id: '4',
            title: "Proche d'une plage",
            img: I2,
            link: `/results/q=?near_beach=1`
        },
    ];

    return (
        <div className='container pb-5'>
            <div className="explore-grid">
                {images.map(({ id, title, img, link }) => (
                    <CatLink to={link} className={`item-${id}`} key={id}>
                        <div className="rounded-4 h-100 p-5 my-5 text-bg-dark position-relative" 
                             style={{ 
                                 backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${img})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center center',
                                 backgroundSize: 'cover',
                                 transition: 'background-size .3s ease',
                             }}>
                            <div className="position-absolute bottom-0 start-0">
                                <h4 className="p-3">{title} <i className="bi bi-arrow-right-circle"></i></h4>
                            </div>
                        </div>
                    </CatLink>
                ))}
            </div>
        </div>
    );
};

export default Explore;
