import { Link } from 'react-router-dom'
import { FooterLink } from '../../../styles/atoms'

function Footer (){
    const year = new Date().getFullYear()

    return (
    <div className="bg-dark text-white mt-5">
        <div className='container py-5'>
            <div className="row">
                <div className="col-sm-5 mb-3">
                    <h2 className='lead fw-bold text-white'>Rejoignez <span className='text-warning'>BookEasily</span></h2>
                    <p>Augmentez votre visibilité et vos chances de location avec BookEasily ! Publiez votre annonce gratuitement et atteignez un large public de locataires potentiels. Gérez vos réservations en ligne et 
                        optimisez vos revenus locatifs avec notre plateforme sécurisée et facile à utiliser !
                    </p>
                    <hr className="w-50 text-warning" />
                    <ul className="list-unstyled d-flex">
                        <li className=" fs-5"><Link className="link-warning" target='_blank' to="https://www.linkedin.com/company/bookeasily/"><i className="bi bi-linkedin" ></i></Link></li>
                        <li className="ms-3 fs-5"><Link className="link-warning" target='_blank' to="https://whatsapp.com/channel/0029VaDMwc8BKfi7czKLbv0w"><i className="bi bi-whatsapp" ></i></Link></li>
                        <li className="ms-3 fs-5"><Link className="link-warning" target='_blank' to="https://www.facebook.com/profile.php?id=100089989105195"><i className="bi bi-facebook" ></i></Link></li>
                        <li className="ms-3 fs-5"><Link className="link-warning" target='_blank' to="https://www.instagram.com/bookeasily?utm_source=qr&igsh=cnlzbGV5NTRjdmZ5"><i className="bi bi-instagram" ></i></Link></li>
                        <li className="ms-3 fs-5"><Link className="link-warning" target='_blank' to="#"><i className="bi bi-youtube" ></i></Link></li>
                    </ul>
                </div>
                <div className="col-sm-3 mb-3">
                    <h3 className='text-white fw-bold'>Liens rapides</h3>
                    <ul className="nav flex-column mt-3">
                        <li className="nav-item mb-2"><i className="bi bi-house-check"></i> <FooterLink to="/" className="p-0 text-white">Accueil</FooterLink></li>
                        <li className="nav-item mb-2"><i className="bi bi-buildings"></i> <FooterLink to={`/properties/categories/hôtels/1`} className=" p-0 text-white">Hotels</FooterLink></li>
                        <li className="nav-item mb-2"><i className="bi bi-building"></i> <FooterLink to={`/properties/categories/meublés/2`} className=" p-0 text-white">Meublés</FooterLink></li>
                        <li className="nav-item mb-2"><i className="bi bi-house-door"></i> <FooterLink to={`/properties/categories/non meublés/3`} className=" p-0 text-white">Locations</FooterLink></li>
                        <li className="nav-item mb-2"><i className="bi bi-arrow-right-circle"></i> <FooterLink to={`/best_deals`} className=" p-0 text-white">Bons Plans</FooterLink></li>
                    </ul>
                </div>
                <div className="col-sm-4 mb-3">
                    <h3 className='text-white fw-bold'>Contacts</h3>
                    <ul className="nav flex-column mt-3">
                        <li className=" mb-2"><i className="bi bi-telephone-forward"></i> <FooterLink to="https://wa.me/237695931631" className=" p-0 text-white">(+237) 695 931 631</FooterLink></li>
                        <li className=" mb-2"><i className="bi bi-envelope-plus"></i> <FooterLink to="mailto:contact@book-easily.com?subject=Besoin%20d'aide%20sur%20BookEasily%20!" className="p-0 text-white">contact@book-easily.com</FooterLink></li>
                        <li className=" mb-2"><i className="bi bi-newspaper"></i> <FooterLink className=" p-0 text-white" data-bs-toggle="modal" data-bs-target="#CGV">Conditions générales de ventes</FooterLink></li>
                        <li className=" mb-2"><i className="bi bi-newspaper"></i> <FooterLink className=" p-0 text-white" data-bs-toggle="modal" data-bs-target="#CGU">Conditions générales d'utilisation</FooterLink></li>
                        <li className="nav-item mb-2"><i className="bi bi-arrow-right-circle"></i> <FooterLink to={`/partners`} className=" p-0 text-white">Devenir Partenaire</FooterLink></li>
                    </ul>
                </div>

                <div className="modal fade" id="CGV" tabIndex="-1" aria-labelledby="label_cgv">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fw-bold" id="label_cgv">Conditions générales de ventes</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            Les présentes conditions générales de vente constituent une partie intégrante de l'Accord Hébergement qui lie un Hébergement à BookEasily (chacun étant une « Partie » et ensemble les « Parties »). 
                            <br /><br />________________________________________
                            <br /><br />1.	OBLIGATIONS DE L'HÉBERGEMENT

                            <br /><br />1.1.	Informations Hébergement

                            <br /><br />a-	Les informations fournies par l'Hébergement dans l'objectif d'être intégrées à la Plateforme doivent comprendre les informations liées à l'Hébergement (y compris des images et des descriptions), ses équipements, ses services et ses Logements disponibles à la réservation, les détails des tarifs, des disponibilités, des conditions d'annulation (délais, annulation remboursable ou non) et des autres conditions et restrictions (les « Informations de l’hébergement »). Les Informations de l’hébergement ne doivent pas contenir de numéro de téléphone ou de fax ou des adresses e-mail (y compris des adresses Skype) ou des sites Internet de réseaux sociaux (y compris Twitter et Facebook) faisant directement référence à l'Hébergement, à ses sites Internet, applications, plateformes, outils et/ou autres dispositifs, ou encore à des sites, applications, plateformes, outils et/ou dispositifs tiers. BookEasily se réserve le droit d'éditer ou de supprimer toutes informations incorrectes, incomplètes ou violent les termes du présent Accord.

                            <br /><br />b-	L'Hébergement est à tout moment responsable de la véracité et de la validité des Informations de l’hébergement, y compris de la disponibilité supplémentaire des logements pendant des périodes données et de la communication d'événements (non matériels) ou de situations extraordinaires (par exemple, travaux de rénovation ou de construction ayant lieu dans l'Hébergement). L'Hébergement a le droit d’effectuer régulièrement la mise à jour quotidienne des Informations de l’hébergement et peut, à tout moment, modifier les tarifs des Logements disponibles à la réservation, et le nombre ou le type de Logements disponibles.

                            <br /><br />c-	Les informations fournies par l'Hébergement pour demeurent la propriété exclusive de l'Hébergement. Les informations fournies par l'Hébergement sont susceptibles d'être éditées ou modifiées par BookEasily.

                            <br /><br />1.2.	Disponibilité et restrictions de Logements

                            <br /><br />a-	L'Hébergement doit assurer à BookEasily la disponibilité de tous les logements enregistrés sur la plateforme. L’hébergement est tenu d’apporter les modifications sur la disponibilité de ses logements sur la plateforme si ces logements ne sont plus disponibles.

                            <br /><br />b-	BookEasily sera autorisée à accorder une réduction sur le tarif du Logement en supportant le coût supplémentaire correspondant, qui ne pourra pas excéder le montant de la commission.

                            <br /><br />c-	L’Hébergement est encouragé à fournir à BookEasily un accès équitable à tous ses Types de Logements (y compris aux diverses conditions et restrictions) et à des tarifs disponibles pendant la durée du présent Accord (durant les périodes de basse et de forte demande (y compris lors de salons, congrès et événements spéciaux).

                            <br /><br />1.3.	Commission

                            <br /><br />a-	L'Hébergement verse à BookEasily une commission pour chaque réservation de Logement effectuée par un Client sur la Plateforme. Cette Commission est calculée conformément à l'Article 1.3 alinéa b et son paiement doit être effectué conformément à l'Article 1.4.

                            <br /><br />b-	Le montant global de Commission par réservation est égal au multiple (i) du nombre de nuits passées à l'Hébergement par le Client, (ii) du tarif réservé par Logement par nuit et de tout autre supplément ou tous frais qui sont compris dans le tarif proposé au moment de la réservation du Logement par un Client sur les Plateformes (tels que le petit-déjeuner, les repas, la location de vélos, les frais de départ tardif/arrivée anticipée, les frais pour le séjour de personnes supplémentaires, les frais de service, les lits d'appoint, etc.), (iii) du nombre de Logements réservés par le Client, et (iv) du pourcentage de Commission y afférant qui est établi dans l'Accord.
                            <br />Pour éviter tout litige, l'Hébergement accepte et reconnaît par la présente que lorsque le Client a payé à l'Hébergement le tarif du logement selon l'Article 3.4 (Paiement Facilité), BookEasily calcule la Commission en cas de non-présentation ou d'annulation conformément à l'Article 3.4 alinéa e.

                            <br />Dans tous les autres cas, la Commission sera facturée :
                            <br />(*) en cas de surréservation : à tout moment et sans tenir compte (de la renonciation aux frais, si elle a lieu) du tarif débité pour le logement, et/ou
                            <br />(**) en cas de frais d'annulation et/ou de non-présentation débités : BookEasily facturera la commission si un partenaire débite le Client pour le tarif du logement et/ou les frais d'annulation/de non-présentation en cas d'annulation et/ou de non-présentation donnant lieu à des frais.
                            <br />La commission est calculée en fonction de la réservation confirmée, et ce à tout moment.

                            <br /><br />c-	Sauf mention contraire dans le présent Accord, le tarif indiqué aux Clients sur la Plateforme doit comprendre la TVA, les taxes sur les ventes, les frais et toute autre taxe ou tout prélèvement (national, gouvernemental, provincial, régional, fédéral, municipal ou local), dans la mesure où lesdits taxes, frais et prélèvements peuvent être raisonnablement calculés à l'avance sans information complémentaire.

                            <br /><br />d-	Le compte partenaire contient les détails de toutes les réservations effectuées à l'Hébergement par l'intermédiaire de la Plateforme, ainsi que la Commission correspondante. 



                            <br /><br />1.4.	Paiement de la commission

                            <br /><br />a-	La Commission pour un mois qui comprend la date de départ (prévue) du Client est facturée (exception faite des annulations sans frais effectuées par l'intermédiaire de BookEasily conformément aux conditions d'annulation de l'hébergement) et doit être payée le mois suivant conformément aux termes suivants :

                            <br />(*) Les factures sont établies sur une base mensuelle et doivent être envoyées à l'Hébergement par Internet ou par e-mail.
                            <br />(**) La Commission facturée pour un mois donné doit être réglée par l'Hébergement dans les 14 jours qui suivent la date d'émission de la facture.
                            <br />(***) Le paiement doit être effectué par l'Hébergement directement à BookEasily par virement bancaire (vers le compte désigné à cet effet par BookEasily) ou par Orange Mony et MTN mobile Mony. BookEasily peut à certaines occasions régler (le montant correspondant de) la facture conformément à l'Article 3.4 pour les réservations dont le paiement a été effectué conformément à l'Article 3.4. Pour éviter tout litige, les autres modes de paiement (tels que des chèques, paypal…) ne sont donc pas acceptés. Tous les frais facturés par la banque pour les virements bancaires et les frais facturer par Orange et MTN lors des dépôts sont à la charge de l'Hébergement.
                            <br />(****) La commission facturée pour un mois calendaire doit être payée par l'Hébergement dans en XAF . BookEasily peut, à sa seule discrétion, établir les factures dans l'une des principales devises (EUR/USD) dans les pays de l'Hébergement qui n’utilise pas le XAF. Dans ce cas, la somme finale due sera convertie dans l'une des principales devises au taux de change en vigueur le dernier jour du mois où est établie la facture (et non le jour du départ du client). 

                            <br /><br />b-	En cas de litige entre BookEasily et l'Hébergement (par exemple, sur le montant de la Commission), tout montant de Commission qui ne fait pas l'objet d'un litige doit être réglé conformément aux termes du présent Accord, nonobstant le statut ou la nature dudit litige.

                            <br /><br />c-	En cas de retard de paiement, BookEasily se réserve le droit de réclamer les intérêts de retard, de suspendre ses services (par exemple, suspendre l'affichage de l'Hébergement sur les Plateformes) et/ou de demander une garantie bancaire ou toute autre forme de garantie financière à l'Hébergement.

                            <br /><br />d-	L'Hébergement qui accuse régulièrement un retard dans le versement de la commission ou de tout autre frais à BookEasily doit, sur première demande de BookEasily, verser un acompte au moins égal à la somme des 3 factures les plus élevées dans le cadre des opérations menées avec BookEasily. L'Acompte sera conservé par BookEasily comme dépôt de garantie au titre des obligations (de paiement) de l'Hébergement qui découlent de l'Accord. À la résiliation du présent Accord, l'Acompte, ou toute partie de l'Acompte qui demeure après la déduction de toute Commission due, de tout paiement à recouvrer et de tous les autres frais dus à BookEasily sera remboursé à l'Hébergement dans les 30 jours qui suivront le règlement intégral des obligations et responsabilités dues (y compris le règlement des Commissions). À la première demande de BookEasily, l'Hébergement devra verser, en tant qu'Acompte complémentaire, toute somme supplémentaire demandée par BookEasily si le montant de la Commission due excède le montant de l'Acompte. À tout moment pendant la validité du présent Accord, BookEasily contrôlera les exécutions (de paiement) de l'Hébergement et pourra décider de rendre l'Acompte à l'Hébergement après une évaluation (selon les critères de BookEasily). Le montant de l'Acompte ne limite ou ne plafonne en aucune façon les obligations de l'Hébergement qui découlent du présent Accord. Aucun intérêt n'est lié audit Acompte.

                            <br /><br />1.5.	Réservation, Réservation Client, réclamations 

                            <br /><br />a-	Lorsqu'une réservation est effectuée par un Client sur la Plateforme, l'Hébergement reçoit une confirmation pour toute réservation effectuée par l'intermédiaire de BookEasily. Les détails de la réservation doivent comprendre la date d'arrivée, le nombre de nuits, le type de logement, le tarif du logement, le nom, l'adresse du Client et toute autre demande particulière effectuée par le Client lors de sa réservation. BookEasily n'est pas responsable de l'exactitude et de l'exhaustivité des informations et des dates fournies par les Clients, ni des obligations de paiement des Clients liées à leur réservation (en ligne). Pour éviter tout litige, l'Hébergement doit, de façon régulière (mais au moins une fois par jour) consulter et vérifier sur son compte BookEasily le statut des réservations effectuées.

                            <br /><br />b-	En effectuant une réservation par l'intermédiaire des Plateformes, un contrat direct, et par conséquent une relation contractuelle, sont passés entre l'Hébergement et le Client uniquement.

                            <br /><br />c-	L'Hébergement est dans l'obligation d'accepter un Client comme sa Partie Contractuelle et de traiter la réservation en ligne conformément aux Informations Hébergement (y compris le tarif) figurant sur les Plateformes au moment de la réservation et conformément à la confirmation de réservation, y compris les informations supplémentaires demandées par le Client et/ou les demandes spéciales effectuées par ce dernier.

                            <br /><br />d-	Toute plainte ou réclamation liée à (tout produit ou service proposé, rendu ou fourni par) l'Hébergement ou toute demande particulière effectuée par le Client doit être traitée par l'Hébergement concerné, sans la médiation ou l'immixtion de BookEasily. BookEasily n'est pas tenue pour responsable, et décline toute responsabilité au regard d'une telle plainte ou réclamation effectuée par un Client. Booking.com peut, à tout moment et à sa seule discrétion, (i) proposer un Service Clients au Client, (ii) agir en qualité d'intermédiaire entre l'Hébergement et le Client, (iii) assister un Client dans ses communications avec l'Hébergement.

                            <br /><br />e-	BookEasily se réserve le droit de suspendre ses services (par exemple, suspendre l'affichage de l'Hébergement sur les Plateformes) à tout hébergement qui fait l’objet de plusieurs plaintes

                            <br /><br />1.6.	Disponibilité et annulation 

                            <br /><br />a-	L'Hébergement doit fournir sur la plateforme BookEasily les Logements disponibles. Si aucun Logement n'est disponible au moment de l'arrivée du Client, l'Hébergement devra :

                            <br />(*) trouver un hébergement de remplacement adapté, de qualité égale ou supérieure à l'Hébergement initialement concerné par la réservation garantie du Client ;
                            <br />(**) assurer le transfert privé et gratuit vers le nouvel Hébergement pour le Client ainsi que pour les personnes qui l'accompagnent et
                            <br />(***) rembourser et dédommager BookEasily et/ou le Client pour tous les frais et dépenses raisonnables (par exemple, coûts liés à l'Hébergement de remplacement, de transport, de téléphone) réalisés, supportés, payés ou induits par le Client et/ou BookEasily suite à la non-disponibilité du logement. 

                            <br /><br /><strong>b-	l'hébergement n'a pas le droit, ni la possibilité d'annuler une réservation .</strong>

                            <br /><br />c-	Les annulations effectuées par le Client avant l'heure et la date stipulées dans les conditions d'annulation n'entraînent pas de commission. Les annulations effectuées par le Client après l'heure et la date stipulées dans les conditions d'annulation entraînent une commission, conformément aux conditions de cet accord.

                            <br /><br />1.7.	Marketing direct auprès des Clients
                            <br />L'Hébergement s'engage à ne pas s'adresser spécifiquement aux Clients obtenus par l'intermédiaire de BookEasily lors de campagnes en ligne ou hors ligne ou par le biais de courriers sollicités ou non sollicités.

                            <br /><br />2.	LICENCE
                            <br />Par le présent Accord, l'Hébergement accorde à BookEasily le droit et une licence (ou sous-licence, si applicable) non-exclusifs, gratuits et à échelle mondiale.
                            <br /><br />3.	CLASSEMENT, NOTES CLIENTS, MARKETING ET PAIEMENT FACILITÉ
                            <br /><br />3.1.	Classement

                            <br /><br />a-	L'ordre dans lequel l'Hébergement apparaît sur la Plateforme est déterminé de façon automatique et unilatérale par BookEasily. Le Classement est influencé par divers facteurs et n'est pas limités qu’au pourcentage de Commission devant être payé par l'Hébergement, mais aussi au nombre de réservations, au nombre de visites sur la page de l'Hébergement concerné sur la Plateforme, aux notes des Clients, au forfait payé par l’hébergement, au nombre d’annulation de l’Hébergement et au respect des délais de paiement par l'Hébergement. 

                            <br /><br />b-	L'Hébergement a la possibilité d'influencer son propre classement en modifiant le pourcentage de commission et les disponibilités pour certaines périodes et en achetant les forfaits. L'Hébergement ne peut effectuer aucune réclamation auprès de BookEasily au sujet du Classement des Hébergements, ledit système de Classement étant automatisé. 

                            <br /><br />3.2.	Notes des clients

                            <br /><br />a-	BookEasily demandera aux Clients ayant séjourné dans un Hébergement d'attribuer des notes à certains aspects de leur séjour dans un Hébergement.

                            <br /><br />b-	BookEasily se réserve le droit de poster lesdits notes sur les Plateformes sous forme des étoiles. L'Hébergement reconnaît que BookEasily n’agit pas en tant qu'éditeur des notes attribuées.

                            <br /><br />c-	BookEasily ne sera pas tenue pour responsable et déclinera toute responsabilité au regard des conséquences qui pourrons subvenir après l’affichage des notes données par les clients.

                            <br /><br />3.3.	 Marketing (en ligne) et publicité

                            <br /><br />a-	BookEasily est autorisée à promouvoir l'Hébergement en employant le ou les noms dudit Hébergement dans le cadre de campagnes de marketing en ligne, y compris lors de campagnes par email et/ou de publicité. 

                            <br /><br />b-	L'Hébergement s'interdit de répondre aux commentaires à la suite du marketing en ligne ou de la publicité initier par BookEasily.

                            <br /><br />3.4.	 Paiement Facilité

                            <br /><br />a-	L’Hébergement accepte et reconnaît que BookEasily (à tout moment en qualité d’agent) faciliter certains modes de paiements pour le (pré)paiement du Tarif du Logement par les Clients et à l’Hébergement, permettant ainsi (selon les disponibilités) aux paiements par virement bancaire, par carte de crédit, par Orange Mony et MTN mobile Mony. BookEasily peut, à certaines occasions, avoir recours à un tiers pour faciliter le processus de paiement.

                            <br /><br />b-	L'Hébergement accepte et reconnaît que pour toute réservation, le montant total de la réservation sera perçu et traité par le Processeur de Paiement, applicables à ladite réservation et indiquées sur la Plateforme.

                            <br /><br />c-	Afin de permettre le transfert du montant perçu correspondant (après déduction et compensation (le cas échéant) des Commissions dues (si autorisé), et de tout autre montant dû à BookEasily), l’Hébergement doit fournir à BookEasily les informations bancaires nécessaires, les numéros des compte Orange Mony et MTN mobile Mony afin que le montant indiqué soit transféré. Pour les paiements par Orange Mony et MTN mobile Mony, BookEasily ou le Processeur de Paiement transférera le Montant Net à l’Hébergement dans les 48h après l’envoi du mail de confirmation de la réservation dans le cas où le client effectue une réservation plus de 72h avant sa date d’arrivé, (ii) dans les 24h après la date de l’arrivé du client dans le cas où le client effectue une réservation moins 72h avant sa date d’arrivé. Pour les paiements par virement bancaire, BookEasily ou le Processeur de Paiement transférera le Montant Net à l’Hébergement dans les 14 jours après le départ du client de l’Hébergement. L’Hébergement accepte et reconnaît que les variations des taux de change et les coûts/frais prélevés par les banques, les sociétés de carte de crédit ou par tout autre intermédiaire (pour le recouvrement, le traitement et le paiement des fonds nécessaires) peuvent engendrer des différences entre le Tarif du Logement (tel qu’indiqué par l’Hébergement dans le système), le montant à percevoir et le montant payé à l’Hébergement. L’Hébergement doit assumer les risques de taux de change ainsi que les coûts facturés par sa banque pour le recouvrement et la conversion du Tarif du Logement. Les fonds retenus par le Processeur de Paiement ou tout autre tiers pour le compte de l’Hébergement n’entraînent aucun intérêt. 

                            <br /><br />d-	Dans le cas où l’Hébergement accepte de rembourser la totalité ou une partie du Tarif du Logement non remboursable payé (en totalité ou en partie), BookEasily se réserve le droit de régler, pour le compte de l'Hébergement, le montant payé au Client en utilisant d’autres montants perçus par le Processeur de Paiement si l’Hébergement n’a pas encore reçu de paiement. Autrement, l’Hébergement devra envoyer la totalité ou une partie du montant de la réservation à BookEasily et BookEasily procèdera immédiatement au remboursement du client, et ne facturera aucune commission à l’Hébergement pour un remboursement total. 

                            <br /><br />e-	Dans le cas d'une non-présentation ou d'une annulation, BookEasily se réserve le droit de facturer la Commission sur le montant du Tarif du Logement perçu et transféré à l'Hébergement. Dans le cas d'une indisponibilité du logement, la Commission est calculée conformément à l'Article 2.3 alinéa b.

                            <br /><br />f-	L’Hébergement s'interdit de facturer (ou d'envoyer une facture à) BookEasily pour la réservation ou le séjour. Rien dans cet Accord n’établit que BookEasily agit en tant que commerçant ou (re)vendeur du Logement.

                            <br /><br />4.	INDEMNISATION ET RESPONSABILITÉ 

                            <br /><br />4.1.	 L'Hébergement doit entièrement indemniser, compenser et dégager BookEasily de toute responsabilité envers tous coûts, responsabilités, dépenses (y compris les honoraires et frais d'avocat), dommages, pertes, obligations, réclamations de toutes sortes, intérêts, pénalités et frais de justice encourus, soufferts ou payés par BookEasily en lien avec :
                            <br />(*) Toute réclamation effectuée par des Clients au regard d'informations inexactes, erronées ou portant à confusion au sujet de l'Hébergement et présentées sur la Plateforme ;
                            <br />(**) Toute réclamation effectuée par des Clients au regard d'un séjour effectué dans un Hébergement, de la non-disponibilité du logement ou de réservations (partiellement) annulées ou erronées ou encore de remboursements du Tarif du Logement ;
                            <br />(***) Toute autre réclamation émanant d'un Client, qui est entièrement ou partiellement attribuable à l'Hébergement, ou qui survient à la suite d'un délit, d'une fraude, d'une faute professionnelle volontaire, d'une négligence ou d'une violation de contrat (y compris de la réservation du Client) due ou attribuable à l'Hébergement; et


                            <br /><br />4.2.	 Si une réclamation est effectuée par un tiers, les Parties doivent agir de bonne foi et employer des efforts commercialement raisonnables pour consulter l'autre Partie, coopérer avec elle et l'assister dans la défense et/ou le règlement d'une telle réclamation, tandis que la Partie Compensatrice se réserve le droit de prendre possession de la réclamation et d'en assumer la défense (en consultation et avec l'accord de la Partie Compensatrice et conformément aux intérêts des deux Parties). Aucune Partie ne doit faire d'aveu, intenter de procès, consentir à l'entrée dans tout jugement ou entrer dans tout compromis ou accord sans l'accord écrit préalable de l'autre Partie (qui ne saurait être raisonnablement retenu, retardé ou soumis à des conditions).

                            <br /><br />4.3.	En aucun cas une Partie ne peut être tenue pour responsable envers toute autre Partie pour toute perte ou tout dommage indirect, spécial, punitif ou consécutif, y compris une perte de production, une perte de profit, une perte de revenu, une perte de contrat, une perte ou un dommage porté à la marque ou à la réputation ou une perte du droit à l'indemnisation, que lesdits dommages soient (supposés être) le résultat d'une violation de contrat, d'une fraude ou de tout autre motif (même si la Partie a été informée de la possibilité de tels dommages ou pertes). La renonciation et le désistement desdits dommages et pertes sont établis par le présent Accord.

                            <br /><br />4.4.	Chaque Partie reconnaît que les recours légaux peuvent être inappropriés pour protéger l'autre Partie contre toute violation du présent Accord ; sans porter préjudice à tout autre droit ou recours autrement disponible pour l'autre Partie, chaque Partie est en droit de demander un recours en injonction ou une action en exécution.

                            <br /><br />5.	DURÉE, RÉSILIATION ET SUSPENSION
                            <br /><br />5.1.	 Sauf mention contraire, le présent Accord prend effet à la date de la signature du présent document, pour une durée indéterminée. Toute Partie peut mettre fin audit Accord, à tout moment et pour tout motif.

                            <br /><br />5.2.	Toute notification ou communication émises par BookEasily concernant la « fermeture de l'Hébergement sur le Site Internet entraîne la résiliation du présent Accord. Suite à la résiliation, à la suspension ou à la fermeture, l'Hébergement est tenu d'honorer toutes les réservations déjà effectuées par les Clients et de payer toutes les Commissions y afférant, conformément aux termes du présent Accord.

                            <br /><br />5.3.	Les événements suivants sont, en tout état de cause, considérés comme une violation substantielle et autorise BookEasily à résilier ou suspendre immédiatement l'Accord:
                            <br />(*) L'Hébergement n'effectue pas le paiement des Commissions avant la date d'échéance ou le jour même ;
                            <br />(**) L'Hébergement poste des Informations Hébergement erronées ou portant à confusion ;
                            <br />(***) L'Hébergement refuse d'accepter une réservation au tarif indiqué sur la réservation ;
                            <br />(****) BookEasily reçoit une ou plusieurs plaintes légitimes et sérieuses de la part d'un ou plusieurs Clients ayant effectué une réservation à l'Hébergement ;
                            <br />(*****) Comportement inapproprié, illicite ou non professionnel envers les Clients ou le personnel de BookEasily; 

                            <br /><br />5.4.	 Après la résiliation du présent Accord, les politiques de confidentialité demeurent valides.

                            <br /><br />6.	CONFIDENTIALITÉ, COMMUNICATION ET UTILISATION DES DONNEES
                            <br /><br />6.1.	Les Parties comprennent et attestent que dans le cadre du présent Accord, chaque Partie peut accéder ou être exposée, directement ou indirectement, à des informations confidentielles au sujet de l'autre Partie (les « Informations Confidentielles »). Les Informations Confidentielles comprennent les Informations Client, le volume de transaction, le plan marketing, le plan commercial et toute autre information commerciale, financière, technique, opérationnelle ou non publique qu'une des Parties Informatrices désigne comme étant privée ou confidentielle ou que toute Partie Informée devrait raisonnablement savoir qu'elle doit être traitée comme privée et confidentielle.

                            <br /><br />6.2.	Chaque Partie accepte que : 
                            <br />(*) toutes les Informations Confidentielles demeurent la propriété exclusive de la Partie Informatrice et que la Partie Informée s'engage à ne pas employer lesdites Informations Confidentielles pour tout objectif n'étant pas établi dans le présent Accord ; 
                            <br />(**) elle doit maintenir et employer des méthodes appropriées pour permettre à ses employés, dirigeants, représentants, parties contractantes et agents (les « Personnes Autorisées ») à maintenir la confidentialité et la protection des Informations Confidentielles ; 
                            <br />(***) elle ne doit divulguer les Informations Confidentielles qu'aux seules Personnes Autorisées qui ont besoin de connaître lesdites Informations dans le cadre du présent Accord ; 
                            <br />(****) elle ne doit pas, et elle doit employer des méthodes appropriées pour s'assurer que les Personnes Autorisées ne copient, publient, divulguent à des autres tiers ou emploient (dans un cadre autre que celui établi dans le présent Accord) les Informations Confidentielles ; et 
                            <br />(*****) elle retourne ou détruise toutes les (copies papier ou électroniques des) Informations Confidentielles sur demande écrite de l'autre Partie.

                            <br /><br />6.3.	Les Parties doivent utiliser des moyens commercialement raisonnables pour assurer la confidentialité et la protection des Informations Client et les protéger contre toute utilisation ou diffusion non autorisée. Chaque Partie accepte de respecter les lois, réglementations et régulations applicables (concernant les données et la confidentialité) de l'autorité compétente auprès de laquelle ladite Partie est constituée.

                            <br /><br />6.4.	En cas de résiliation de l’accord par l’une ou l’autre des Parties, quelle qu’en soit la cause, les Parties s’interdisent d’utiliser et de divulguer tout ou partie des informations confidentielles transmises dans le cadre de l’exécution du présent accord.

                            </div>
                            <div className="modal-footer">
                                <Link className="btn btn-warning fw-bold w-100 rounded-4" to='https://www.book-easily.com/conditions_generales_de_ventes_sur_bookeasily.pdf' download>Télécharger</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="CGU" tabIndex="-1" aria-labelledby="label_cgu">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fw-bold" id="label_cgv">Conditions générales d'utilisation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            Résumé des présentes Conditions
                            <br /><br />En utilisant notre plateforme, vous acceptez nos Conditions. Si vous n’acceptez pas l’une de ces conditions, merci de ne pas utiliser notre Plateforme.
                            Toutes ces informations sont importantes car elles définissent (avec l’e-mail de confirmation de votre réservation et toute information précontractuelle fournie avant votre réservation) les conditions juridiques selon lesquelles les partenaires proposent leurs services par l’intermédiaire de notre Plateforme.
                            Le présent résumé ne fait pas partie de nos Conditions, et ne constitue pas un document juridique. Il s’agit simplement d’une explication de nos Conditions. Nous vous conseillons de lire chaque article de ces conditions. Si vous avez des questions n’hésitez pas a contacter le service client de BookEasily.
                            <br /><br />A1. Notre Plateforme
                            <br /><br />1. Nous collectons des informations auprès des partenaires et nous ne pouvons garantir que toutes les informations sont exactes, mais en mettant à disposition notre Plateforme, nous prenons des précautions suffisantes et agissons avec professionnalisme. Sauf manquement ou négligence de notre part, nous ne pouvons être tenus pour responsables d’éventuelles erreurs, interruptions ou informations manquantes. Bien entendu, nous ferons tout ce qui est en notre pouvoir pour les corriger dès que nous en aurons connaissance.
                            <br />2. Notre Plateforme ne constitue pas une recommandation ou une approbation d’un Partenaire ou de ses produits, ses services, ses équipements, ses véhicules, etc.
                            <br />3. Pour effectuer une Réservation, vous devrez peut-être créer un Compte. Veuillez-vous assurer que toutes vos informations (y compris les coordonnées de paiement et de contact) sont correctes et à jour. Vous êtes responsable de toutes les actions réalisées sur votre Compte, alors ne laissez personne d’autre l’utiliser et veuillez garder votre nom d’utilisateur et votre mot de passe secrets.
                            <br />4. Sauf indication contraire, vous devez avoir au moins 18 ans pour utiliser la Plateforme.


                            <br /><br /><br />A2. Nos valeurs
                            <br /><br />Vous devrez :
                            <br />-	respecter Nos valeurs ;
                            <br />-	vous conformer à toutes les lois applicables ;
                            <br />-	coopérer à tous les contrôles anti-fraude/anti-blanchiment que nous devons effectuer ;
                            <br />-	vous abstenir d’utiliser la Plateforme pour causer des désagréments ou faire de fausses Réservations ;
                            <br />-	utiliser la Plateforme aux fins prévues ;
                            <br />-	vous abstenir de causer des désagréments ou des dommages, et de vous comporter de manière inappropriée envers le personnel du partenaire (ou toute autre personne à cet égard)
                            <br /><br /><br />A3. Relation contractuelle
                            <br /><br />1. Lorsque vous effectuez une Réservation, celle-ci est effectuée directement auprès de notre partenaire, nous ne constituons pas une « partie contractante ».
                            <br />2. Notre Plateforme ne présente que les Hébergements entretenant une relation commerciale avec nous et elle ne présente pas nécessairement tous leurs produits ou services.
                            <br />3. Les informations sur les partenaires (par ex. les équipements, les règles de la maison) et leurs Expériences de voyage (par exemple, les tarifs, les disponibilités et les conditions d’annulation) sont basées sur les données qu’ils nous transmettent.
                            <br /><br /><br />A4. Notre prestation
                            <br /><br />1. Nous mettons à disposition la Plateforme sur laquelle nos partenaires peuvent promouvoir et vendre leurs Hébergements - et vous pouvez les rechercher, les comparer et les réserver. Notre Plateforme vous permet de découvrir des Hébergements au Cameroun et en Afrique en général et nos résultats de recherche vous permettent de trouver facilement celui qui vous convient.
                            <br />2. Une fois que vous aurez réservé votre Hébergement, nous confirmerons les détails de votre Réservation, y compris les noms du ou des clients, auprès de vous et de notre partenaire.


                            <br /><br /><br />A5. Que faire pour effectuer une réservation d’Hébergement et durant votre séjour?
                            <br /><br />1. Renseignez correctement toutes vos coordonnées dans la plateforme afin que BookEasily et/ou le partenaire puisse vous fournir des informations sur votre Réservation et, si nécessaire, vous contacter.
                            <br />2. Lisez attentivement ces Conditions et celles affichées pendant le processus de réservation.
                            <br />3. Prenez soin de l’Hébergement ainsi que des meubles, des équipements, des appareils électroniques et autres éléments, et laissez les lieux dans le même état que celui dans lequel vous les avez trouvés en arrivant. Si un objet est cassé, endommagé ou perdu, assurez-vous de le signaler au personnel sur place (dès que vous le pouvez, et surtout avant votre départ).
                            <br />4. Préservez la sécurité de l’Hébergement et de son mobilier durant votre séjour. 
                            <br /><br /><br />A6. Tarif et règlement
                            <br /><br /> A6.1. Tarifs
                            <br /><br />1. Lorsque vous effectuez une Réservation, vous acceptez de payer le tarif de l’Hébergement, y compris tous les frais et taxes qui peuvent s’appliquer.
                            <br />2. Les erreurs et fautes d’impression évidentes ne sont pas contraignantes. Par exemple : si vous réservez une nuit dans une suite qui a été proposée par erreur pour 1000XAF, votre réservation peut être annulée et nous vous rembourserons le montant que vous avez payé.
                            <br /><br />A6.2. Paiement
                            <br /><br />1. Pour certains Hébergement, le partenaire exigera un Paiement initial.
                            <br />2. Si le partenaire exige un Paiement initial, celui-ci peut être prélevé lorsque vous effectuez votre Réservation, et il peut être non remboursable. Par conséquent, avant de réserver, veuillez vérifier les conditions de Paiement initial de l’Hébergement (accessible pendant le processus de réservation), sur laquelle nous n’avons aucune influence et dont nous ne sommes pas responsables. 
                            <br />3. Si vous avez connaissance d’un comportement frauduleux ou d’une utilisation non autorisée de votre Moyen de paiement, ou si vous soupçonnez de tels comportements, veuillez contacter votre fournisseur de paiement dès que possible.
                            <br />4. Nous conserverons les détails de votre Moyen de paiement pour les transactions futures après avoir recueilli votre consentement.
                            <br /><br />A7. Modifications, annulations et remboursements
                            <br /><br />1. Lorsque vous effectuez une Réservation, vous acceptez les conditions applicables telles qu’elles sont affichées dans le processus de réservation. Les conditions d’annulation de chaque Prestataire de service et toutes les autres conditions (le petit-déjeuner, les animaux domestiques, les cartes acceptées, etc.) sont accessibles sur notre Plateforme : sur les pages d’informations du partenaire, pendant le processus de réservation, dans les clauses et dans l’e-mail de confirmation.
                            <br />2. Si vous annulez une Réservation ou ne vous présentez pas, les frais d’annulation/de non-présentation et tout remboursement dépendront des conditions d’annulation/de non-présentation de notre partenaire.
                            <br />3. Certaines Réservations ne peuvent pas être annulées gratuitement, tandis que d’autres ne peuvent être annulées gratuitement qu’avant une date limite.
                            <br />4. Si vous pensez que vous n’arriverez pas à l’heure, veuillez contacter le responsable de l’Hébergement et lui indiquer votre heure d’arrivée. Il vous incombe de vous assurer d’arriver à l’heure ; à défaut, nous ne sommes pas responsables des coûts associés (par exemple, l’annulation de votre réservation ou les frais que nitre partenaire pourrait vous facturer).
                            <br />5. En tant que personne effectuant la Réservation, vous êtes responsable des actions et du comportement de chaque membre du groupe. Vous êtes également tenu(e) d’obtenir leur autorisation avant de nous fournir leurs données personnelles.
                            <br />6. vous pouvez également modifier votre réservation, BookEasily enverra à vous et à l’Hébergement un mail de confirmation.
                            <br /><br />A8. Droits de propriété intellectuelle
                            <br /><br />1. Sauf indication contraire, tous les droits sur notre Plateforme (technologie, contenu, marques déposées, apparence, etc.) appartiennent à BookEasily ; et en utilisant notre Plateforme, vous acceptez de le faire uniquement aux fins prévues.
                            <br />2. Vous n’êtes pas autorisé(e) à surveiller, copier, récupérer/explorer, télécharger, reproduire ou utiliser quoi que ce soit sur notre Plateforme à des fins commerciales sans l’autorisation écrite de BookEasily.
                            <br />3. Nous surveillons de près chaque visite sur notre Plateforme, et nous bloquerons toute personne (et tout système automatisé) que nous soupçonnons.
                            <br /><br />A9. Que faire en cas de problème ?
                            <br /><br />1. Si vous avez une question ou une réclamation, veuillez contacter notre Service Clients. 
                            <br />2. Toutes les demandes et les réclamations sont enregistrées et les plus urgentes sont traitées en priorité.
                            <br />3. Nous nous efforçons de résoudre les litiges directement avec vous et nous ne sommes pas tenus de nous soumettre à des procédures alternatives de résolution des litiges gérées par des partenaires.
                            <br /><br />A10. Communication avec le Prestataire de service
                            <br /><br />Nous pouvons vous aider à communiquer avec votre Prestataire de service, mais cela ne signifie pas que nous assumons la responsabilité de tout ce que le Prestataire de service fait ou ne fait pas. Nous ne pouvons pas vous garantir que ce dernier vous lira ou fera ce que vous lui demandez. Si vous avez besoin d’aide, veuillez nous contacter via notre Centre d’aide.
                            <br /><br />A11. Mesures prises contre les comportements inacceptables
                            <br /><br />1. En cas de violation des présentes Conditions ou de non-respect des lois ou réglementations applicables, nous nous réservons le droit de :
                            <br />•	vous empêcher d’effectuer toute Réservation ;
                            <br />•	annuler toute Réservation que vous avez déjà effectuée ;
                            <br />•	vous empêcher d’utiliser : notre Plateforme, notre Service Clients, votre Compte.
                            <br />2. Si nous annulons une Réservation pour cette raison, il se peut (selon les circonstances) que vous n’ayez pas droit à un remboursement. Nous pouvons si nécessaire vous indiquer la raison pour laquelle nous avons annulé votre Réservation. Si vous pensez que nous avons annulé votre Réservation par erreur, veuillez contacter notre Service Clients.
                            <br /><br />A12. Limitation de responsabilité
                            <br /><br />1. En cas de violation des présentes Conditions et/ou des conditions du partenaire, nous ne serons pas responsables des frais que vous aurez encourus en conséquence.
                            <br />2. Nous ne sommes pas responsables :
                            <br />•	de toute perte ou tout dommage qui n’était pas raisonnablement prévisible lorsque vous avez effectué votre Réservation,
                            <br />•	de tout événement raisonnablement indépendant de notre volonté.
                            <br />3. Nous ne faisons aucune promesse concernant les produits et services des partenaires hormis ce que nous indiquons expressément dans les présentes Conditions.
                            <br />4. À des fins de clarté, les présentes Conditions sont conclues entre vous et nous. Aucune disposition des présentes Conditions ne confère de droit à un tiers autre que le partenaire.
                            <br />5. Vous pouvez être protégé(e) par des lois et réglementations obligatoires sur la protection des consommateurs, qui vous garantissent des droits qu’aucune condition d’entreprise ne peut remplacer. En cas de contradiction entre ces lois et réglementations ainsi que les présentes Conditions, les lois et réglementations obligatoires en matière de protection des consommateurs prévaudront.
                            <br /><br />A13. Clause de modification
                            <br /><br />1. Nous pouvons apporter des modifications aux présentes Conditions. Si ces changements sont importants, nous vous en informerons avant qu’ils n’entrent en vigueur, à moins que ces changements ne soient requis par la loi en vigueur.
                            <br />2. Si vous n’acceptez pas les modifications, veuillez ne pas utiliser notre Plateforme.
                            <br />3. Autrement, la poursuite de l’utilisation de notre Plateforme après la date d’entrée en vigueur des modifications proposées vaudra acceptation des Conditions révisées.
                            <br />4. Toutes vos Réservations existantes continueront d’être régies par les Conditions qui s’appliquaient au moment où la Réservation a été effectuée.
                            </div>
                            <div className="modal-footer">
                                <Link className="btn btn-warning fw-bold w-100 rounded-4" to='https://www.book-easily.com/conditions_generales_utilisation_de_bookeasily.pdf' download>Télécharger</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between border-top mb-5 mb-md-0 pt-4 mt-2">
                <p className='text-center'><i className='align-middle bi bi-geo-alt text-danger'></i> Douala, Cameroun</p>
                <p className='text-center text-white order-sm-first'>Copyright &copy; {year}, Réalisé par <Link className="link-warning" to="https://www.linkedin.com/in/marc-tatchou-85891a243" target='_blank'> <strong className='text-warning'>Net98</strong></Link>. Tous droits réservés !</p>
            </div>

            {/* <div className="d-flex flex-column flex-sm-row justify-content-center text-center">
                <FooterContainer>
                    <NightModeButton onClick={() => toggleTheme()} >
                        Thème {theme === 'light' ? <i className="bi bi-cloud-sun-fill"></i> : <i className="bi bi-cloud-moon-fill"></i> }
                    </NightModeButton>
                </FooterContainer>
            </div> */}
        </div>
    </div>
    )
}

export default Footer