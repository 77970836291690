import { Fragment } from "react"
import { Link } from "react-router-dom"
import { useFetch, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import Admin from "../../../composants/dashboard/header"
import { AdminSection, DeleteButton, ErrorSection, HeaderContainer, HeroAdminSection, HeroTitleAdmin, InfosUser, MainContainer, StyledLink, StyledTitle2 } from "../../../styles/atoms"
import Column from "../../../composants/dashboard/column"
import Loading from "../../../composants/public/widgets/loading"
import Stats from "../../../composants/dashboard/stats"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"
import Header from "../../../composants/public/sections/header"

function Profil () {
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null 
  document.title = `BookEasily | ${ user ? user.first_name :  null } - Informations personelles`
  const x_xsrf_token = localStorage.getItem("cookies")

  const { theme } = useTheme()

  const id = user.id

  let created_at = user.created_at
  created_at = new Date(created_at)
  created_at = created_at.toLocaleDateString()

  let updated_at = user.updated_at
  updated_at = new Date(updated_at)
  updated_at = updated_at.toLocaleDateString()

  const { isLoading11, data11, error11 } = useFetch(
    `https://api.book-easily.com/api/${ user.role_id === 3 ? `admin` : `user` }/count_items/bookings`,
    token
  ) 
  const countBookings = data11?.data?.visible + data11?.data?.hidden

  async function handleDelete(e, id) {
    e.preventDefault() 
    await fetch(`https://api.book-easily.com/api/delete/users/${id}`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json; charset=UTF-8',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
        },
    })
    .then((response) => response.json())
    .then((data) => {
        if (data?.status === 1) {
            localStorage.clear()
            window.location.assign("/") 
        }
    })
    .catch((err) => {
        console.log(err.message)
    })
  }

  if (error11) {
    return (
      <Error />
    )
  }

    return user.email_verified === 1 ? (
      <Fragment>
        {/* Hearder Section  */}
            <Admin />

            <MainContainer theme={theme}>
              <HeroAdminSection theme={theme}>
                <HeroTitleAdmin theme={theme}>Bienvenue { user ? user.first_name :  null } ! </HeroTitleAdmin>
                <div className="row">
                  <StyledTitle2 theme={theme}>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                        <li className="breadcrumb-item text-warning" aria-current="page">Profil </li>
                      </ol>
                    </nav>
                  </StyledTitle2>
                </div>
              </HeroAdminSection>

            
              <AdminSection>
                <div className="col-md-4 col-lg 3">
                  <Column theme={theme} />
                </div>

                <div className="col-md-8 col-lg-9"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                { isLoading11 ? (
                <Loading />
                ) : (
                  <Fragment>
                  { user.role_id === 3 ? 
                  <Stats countBookings={countBookings} />
                  : null }
                  <div className="table-responsive rounded-4 mt-3">
                    <div className="my__card card mb-3">
                      <InfosUser className="card-header bg-warning text-dark fw-bold">
                        <div>
                          <span>Informations personnelles</span>
                        </div>
                        <div className="d-flex flex-row">
                          <div>
                            <StyledLink to={`/dashboard/user/update/${id}`}><i className="bi bi-pencil-fill"></i> Modifiez</StyledLink>
                          </div>
                          <div className="text-end">
                            <form onSubmit={(e) => handleDelete(e, id) }>
                                <DeleteButton type="submit"><i className="bi bi-x-circle-fill"></i> Supprimez</DeleteButton>
                            </form>
                          </div>
                        </div>
                      </InfosUser>
                      <ul className="list-group list-group-flush text-md-center">
                        <li className="list-group-item"><strong>Nom :</strong> {user.last_name} </li>
                        <li className="list-group-item"><strong>Prénom :</strong> {user.first_name}</li>
                        <li className="list-group-item"><strong>Adresse :</strong> {user.address}</li>
                        <li className="list-group-item"><strong>Email :</strong> {user.email}</li>
                        <li className="list-group-item"><strong>Téléphone :</strong> {user.phone}</li>
                        <li className="list-group-item"><strong>Dernière mise à jour :</strong> {updated_at}</li>
                        <li className="list-group-item"><strong>Crée le :</strong> {created_at}</li>
                      </ul>
                    </div>
                  </div>
                  </Fragment>
                )}   
                </div>
              </AdminSection>  
            </MainContainer>
          <Footer />
          <ScrollButton />
          <MobileBottomHeader/>
        </Fragment>
    ) : (
    <Fragment>
      <HeaderContainer theme = {theme}>
        <Header/>
      </HeaderContainer>
      <MainContainer theme = {theme}>
        <ErrorSection theme={theme}>
          <h3>Oups, vous devez avoir un compte vérifié pour accéder à cette page !</h3>
          <StyledLink theme={theme} to='/sign-up'>Créer votre compte</StyledLink> 
        </ErrorSection>
      </MainContainer>
      <Footer />
      <ScrollButton />
      <MobileBottomHeader/>
    </Fragment>
)
}

export default Profil