import React, { Fragment, useContext, useCallback } from 'react';
import { Link, Form } from 'react-router-dom';
import img from '../../../assets/user.png';
import logo from '../../../assets/logo.png';
import { ThemeContext } from '../../../context';
import { HeaderContainer, HomeLogo, LogoutButton, MobileSearch, NavLink, StyledLink, UserProfil } from '../../../styles/atoms';

function Header() {
    const { theme } = useContext(ThemeContext);
    const user = JSON.parse(localStorage.getItem("user") || "null");
    const token = JSON.parse(localStorage.getItem("token") || "null");
    const x_xsrf_token = localStorage.getItem("cookies");

    const logout = useCallback(async (token) => {
        try {
            await fetch('https://api.book-easily.com/api/logout', {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                credentials: 'include',
                redirect: 'manual',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json; charset=UTF-8',
                    'X-XSRF-TOKEN': x_xsrf_token
                },
            });
            localStorage.clear();
            window.location.assign('/');
        } catch (err) {
            console.error('Logout failed:', err.message);
        }
    }, [x_xsrf_token]);

    const handleLogout = useCallback((e) => {
        e.preventDefault();
        if (token) logout(token);
    }, [logout, token]);

    return (
        <HeaderContainer theme={theme}>
            <nav className="navbar fixed-top bg-white navbar-expand-lg border border-light">
                <div className="container pb-1">
                    <Link to="/">
                        <HomeLogo src={logo} alt="logo BookEasily" />
                    </Link>

                    <MobileSearch method='GET' action="/results/q=" role='search' id='search'>
                        <input
                            className="form-control justify-content-center rounded-4"
                            type="search"
                            name='text'
                            id='text'
                            placeholder="🔍 Chercher..."
                            aria-label="search"
                        />
                    </MobileSearch>

                    <button
                        className="navbar-toggler collapsed bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="navbar-collapse collapse align-items-center justify-content-center text-center text-capitalize" id="navbarCollapse">
                        <ul className="navbar-nav m-auto mb-2 mb-md-0">
                            <li className='px-1'>
                                <NavLink to="/" $theme={theme}>Accueil</NavLink>
                            </li>
                            <li className='px-1'>
                                <NavLink to={`/properties/categories/hôtels/1`} $theme={theme}>Hôtels</NavLink>
                            </li>
                            <li className='px-1'>
                                <NavLink to={`/properties/categories/meublés/2`} $theme={theme}>Meublés</NavLink>
                            </li>
                            <li className='px-1'>
                                <NavLink to={`/properties/categories/non meublés/3`} $theme={theme}>Locations</NavLink>
                            </li>
                            <li className='px-1'>
                                <NavLink to="/best_deals" $theme={theme}>Bons Plans</NavLink>
                            </li>
                            <li className='px-1'>
                                <NavLink to="/about" $theme={theme}>à Propos</NavLink>
                            </li>
                            {!token && (
                                <li className='px-1'>
                                    <NavLink to="/partners" $theme={theme}>Devenir partenaire</NavLink>
                                </li>
                            )}
                        </ul>

                        <div className="d-flex justify-content-center">
                            {/* <SearchForm method='GET' action="/results/q=" className='mx-2' role='search'>
                                <input
                                    className="form-control justify-content-center rounded-4"
                                    type="search"
                                    name='text'
                                    id='text'
                                    placeholder="🔍 Chercher..."
                                    aria-label="search"
                                />
                            </SearchForm> */}
                            <div className="btn-group bg-white border rounded-4">
                                <button
                                    type="button"
                                    className="btn dropdown-toggle fw-bold"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {user ? (
                                        <StyledLink $theme={theme} className='text-capitalize'>
                                            <UserProfil src={user.photo_url || img} alt={user.last_name} />
                                            <span> {user.first_name}</span>
                                        </StyledLink>
                                    ) : (
                                        <StyledLink $theme={theme} className='text-capitalize'>
                                            <i className="bi bi-person-circle text-warning"></i>
                                            <span> Compte</span>
                                        </StyledLink>
                                    )}
                                </button>
                                <ul className="dropdown-menu">
                                    {user ? (
                                        <>
                                            {user.email_verified === 1 && (
                                                <>
                                                    <li className="dropdown-item">
                                                        <NavLink to="/dashboard/user" $theme={theme}>Profil</NavLink>
                                                    </li>
                                                    <li className="dropdown-item">
                                                        <NavLink to="/dashboard" $theme={theme}>Statistiques</NavLink>
                                                    </li>
                                                    <li className="dropdown-item">
                                                        <NavLink to="/dashboard/categories/hotels/new_ads" $theme={theme}>Hôtels +</NavLink>
                                                    </li>
                                                    <li className="dropdown-item">
                                                        <NavLink to="/dashboard/categories/meubles/new_ads" $theme={theme}>Meublés +</NavLink>
                                                    </li>
                                                    <li className="dropdown-item">
                                                        <NavLink to="/dashboard/categories/non_meubles/new_ads" $theme={theme}>Non Meublés +</NavLink>
                                                    </li>
                                                    <hr />
                                                </>
                                            )}
                                            <li className="dropdown-item">
                                                <Form method='POST' onSubmit={handleLogout}>
                                                    <LogoutButton type='submit' $theme={theme}>Déconnexion</LogoutButton>
                                                </Form>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="dropdown-item">
                                                <NavLink to="/sign-in" $theme={theme}>Se connecter</NavLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <NavLink to="/sign-up" $theme={theme}>S'inscrire</NavLink>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </HeaderContainer>
    );
}

export default Header;
