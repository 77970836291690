import { Link, useParams } from "react-router-dom"
import { Fragment, useState } from "react"
import logo from '../../../assets/logo.png'
import { HomeLogo } from "../../../styles/atoms"
import HelpButton from "../../../composants/public/widgets/helpButton"

function PasswordUpdate() {
    document.title = `BookEasily | Modifiez votre mot de passe`
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const x_xsrf_token = localStorage.getItem("cookies")

    const { param_id } = useParams()
    
    const [status, setStatus] = useState('')
    const [old_password, setOldPassword] = useState('')
    const [new_password, setNewPassword] = useState('')
    const [load, setLoad] = useState(0)

    const forms = new FormData()
    forms.append('old_password', old_password)
    forms.append('new_password', new_password)

    async function handleLogin(e, param_id, forms) {
        e.preventDefault() 
        setLoad(1)

        await fetch(`https://api.book-easily.com/api/users/${param_id}`, {
            method: "POST",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json; charset=UTF-8',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            },
            body: forms,
        })
        .then((response) => response.json())
        .then((data) => {
            setStatus(data?.status)
            if (data?.status === 1) {
                setLoad(0)
                window.location.assign("/dashboard")
            }
        })
        .catch((err) => {
            setLoad(0)
           console.log(err.message)
        })
    } 

    return (
        <Fragment>
            <div className='container justify-content-center col-md-6 offset-md-3 col-12'>
                <form className="my__form g-2 p-3 my-5 mx-3" onSubmit={(e) => handleLogin(e, param_id, forms)}>
                    <div className="col-md-12 text-center">
                        <Link to="/dashboard">
                            <HomeLogo src={logo} alt="logo bookEasily" />
                        </Link>
                    </div>
                    { status === 1 && 
                        (<div className="alert alert-info" role="alert">
                            Votre mot de passe a bien été modifié ! Voir votre <Link to='/dashboard' >tableau de bord</Link> . 
                        </div>)
                    }
                    { status === 0 && 
                        (<div className="alert alert-danger" role="alert">
                            Vérifiez à nouveau vos informations. 
                        </div>)
                    }
                    <div className="col-md-12 py-1">
                        <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/dashboard" className='text-warning'> Accueil</Link>
                    </div>
                    <div className="col-md-12 py-1">
                        <input type="password" className="form-control rounded-4" id="old_password" name="old_password" placeholder="Entrez votre ancien mot de passe..." value={old_password} onChange={(e) => setOldPassword(e.target.value) } required/>
                        <small className="text-muted">Ancien mot de passe *</small>
                    </div>
                    <div className="col-md-12 py-1">
                        <input type="password" className="form-control rounded-4" id="new_password" name="new_password" placeholder="Entrez votre nouveau mot de passe..." value={new_password} onChange={(e) => setNewPassword(e.target.value) } required/>
                        <small className="text-muted">Nouveau mot de passe *</small>
                    </div>
                    <div className="col-12 my-1 text-warning">
                        * = champ obligatoire
                    </div>
                    <div className="col-12 text-end my-3">
                    { load === 0 ? 
                            <button type="submit" className="btn btn-outline-warning fw-bold w-100 rounded-4">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Mettre à jour</span>  }</button>
                        :
                            <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-4" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                            </button>
                    }
                    </div>
                </form>
            </div>
            <HelpButton />
        </Fragment>

    )
}

export default PasswordUpdate