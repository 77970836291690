import { Fragment, useEffect, useState } from "react"
import { Link, useParams  } from "react-router-dom"
import { GridBoardForm, HomeLogo } from "../../../styles/atoms"
import logo from '../../../assets/logo.png'
import HelpButton from "../../../composants/public/widgets/helpButton"

function AvailableUpdate () {
  document.title = `BookEasily | Modifiez vos informations de reservation`

  const { param_id } = useParams()
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const x_xsrf_token = localStorage.getItem("cookies")

  const [entering_at, setEnteringAt] = useState('')
  const [living_at, setLivingAt] = useState('')
  const [n_pieces, setNPieces] = useState('')
  const [status, setStatus] = useState('')
  const [booking, setBooking] = useState('')
  const [load, setLoad] = useState(0)

  useEffect(() => {
    const fetchPost = async (param_id, token, x_xsrf_token) => {
      const response = await fetch(
        `https://api.book-easily.com/api/booking/${param_id}`, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
              'Authorization': `Bearer ${token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Accept": "application/json",
              'X-XSRF-TOKEN': `${x_xsrf_token}`,
          }
        }
      )
      const data = await response.json()
      if (data?.status === 1) {
        setBooking(data?.data)
        setEnteringAt(data?.data.entering_at)
        setLivingAt(data?.data.living_at)
        setNPieces(data?.data.n_pieces)
      }
    };
    fetchPost(param_id, x_xsrf_token)
  }, [param_id, token, x_xsrf_token])

  const forms = new FormData()
  n_pieces !== booking.n_pieces && forms.append("n_pieces", n_pieces)
  entering_at !== booking.entering_at && forms.append("entering_at", entering_at)
  living_at !== booking.living_at && forms.append("living_at", living_at)
  forms.append("ad_id", param_id)
  forms.append("booked", 1)

  async function handleBooking(e, forms, token, x_xsrf_token, param_id) {
    e.preventDefault()
    setLoad(1)

    await fetch(`https://api.book-easily.com/api/booking/${param_id}`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        body: forms,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept' : 'application/json; charset=UTF-8',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        },
    })
    .then((response) => response.json())
    .then((data) => {
      setLoad(0)
      setStatus(data?.status)
      if (data?.status === 1) {
        window.location.assign('/dashboard')
      }
    })
    .catch((err) => {
      setLoad(0)
      console.log(err.message)
    })
  } 

  return (
    <Fragment>
      <div className='container justify-content-center col-md-10 offset-md-1 col-12'>
        <form className="my__form g-2 p-3 my-5 mx-3" onSubmit={(e) => handleBooking(e, forms, token, x_xsrf_token, param_id)}>
          <div className="col-md-12 text-center">
            <Link to="/dashboard">
              <HomeLogo src={logo} alt="logo bookEasily" />
            </Link>
          </div>
          { status === 1 && 
              (<div className="alert alert-info" role="alert">
                  Votre calendrier de disponibilité a été modifiée avec succès ! 
              </div> )
          }
          { status === 0 && 
              (<div className="alert alert-danger" role="alert">
                  Vérifiez à nouveau vos informations. 
              </div>)
          }
          <div className="col-12 my-1">
            <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to= { `/dashboard` } className='text-warning'> Accueil</Link>
          </div>  
          <GridBoardForm>
            <div className="col-12">
                <label htmlFor="start_date" className="form-label">Date de debut</label>
                <div className="input-group">
                    <div className="input-group-text">Du</div>
                    <input type="datetime-local" className="form-control" id="start_date" max={living_at} value={entering_at} name="start_date" onChange={(e) => setEnteringAt(e.target.value)}/>
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="end_date" className="form-label">Date de fin</label>
                <div className="input-group">
                    <div className="input-group-text">Au</div>
                    <input type="datetime-local" className="form-control" id="end_date" min={entering_at} value={living_at} name="end_date" onChange={(e) => setLivingAt(e.target.value) }/>
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="number_pieces" className="form-label">Nombre de pièces</label>
                <div className="input-group">
                    <input type="number" className="form-control rounded-4" placeholder="Nombre de pièces" value={n_pieces} id="number_pieces" name="number_pieces" onChange={(e) => setNPieces(e.target.value)} />
                </div>
            </div>
        </GridBoardForm>
          { load === 0 ? 
                  <button className="w-100 btn btn-outline-warning fw-bold my-3 rounded-4" type="submit">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Modifiez vos informations</span>  }</button>
              :
                  <button className="btn btn-outline-warning fw-bold my-3 w-100 rounded-4" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                  </button>
          }
        </form>
      </div>
      <HelpButton />
    </Fragment>
  )
}

export default AvailableUpdate