import { createGlobalStyle } from 'styled-components'
import { useTheme } from '../hooks'
import colors from './colors'

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: 'Montserrat', Helvetica, sans-serif;
    }

    @keyframes blink {
      0%, 100% {
          opacity: 1;
      }
      50% {
          opacity: 0;
      }
    }

    body {
        font-family: 'Montserrat', Helvetica, sans-serif;
        background-color: ${(props) =>
          props.isDarkMode ? colors.dark : colors.light};
        margin: 0;
        font-size: 1rem;
        position: relative;
        height: 100%;
    }

    em {
      font-weight: 600;
    }

    h1 {
      font-size: 1.8rem;
      font-weight: 700;

      @media (max-width: 992px) {
        font-size: 1.4rem;
      }
    }
    
    h2 {
      font-size: 1.6rem;
      font-weight: 700;

      @media (max-width: 992px) {
        font-size: 1.2rem;
      }
    }

    h3 {
      font-size: 1.1rem;
    }

    h4 {
      font-size: 1rem;
    }

    h5, h6, p, li, th, td {
      font-size: 0.9rem;
    }

    ol li {
      font-size: 1.2rem;
    }

    .accordion-button {
      font-weight: 500;
      &:active, &:focus  {
          background-color: #000;
          color: #fff;
          box-shadow: none;
          &::after {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e")
          }
      }
      &:not(.collapsed) {
          background-color: #000;
          color: #fff;
          &::after {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e")
          }
      }
    }

    .my__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      opacity: 0.95;
    }

    .my__card {
      background-color: white;
      box-shadow: rgba(255, 255, 0, 0.3) 2px 1px 1px 2px inset;
      border-radius: 10px;
    }

    .preview-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 10px;
    }
    
    .preview-item {
        position: relative;
        width: 100px;
        height: 100px;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .preview-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        background-size: cover;
    }  

    .embla {
      overflow: hidden;
      width: 100%;
    }
    
    .embla__container {
      display: flex;
    }
    
    .embla__slide {
      position: relative;
      flex: 0 0 80%; /* Contrôle de la taille du slide */
      margin-right: 0px; /* Espace entre les slides */
    }
    
    .embla__prev, .embla__next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: #333;
      color: #fff;
      border: none;
      padding: 10px;
      cursor: pointer;
      z-index: 10;
    }
    
    .embla__prev {
      left: 10px;
    }
    
    .embla__next {
      right: 10px;
    }   
    
    .hero-partner {
      .images {
          position: relative;
          height: 400px;
          .item {
              position: absolute;
              width: 70%;
              height: 70%;
              &.first {
                  left: 30%;
                  z-index: 1;
                  .image-badge {
                      top: 40px;
                  }
              }
              &.second {
                  z-index: 2;
                  top: 30%;
                  .image-badge {
                      top: -40px;
                  }
              }
              >img {
                  height: 100%;
                  object-fit: contain;
                  width: 100%;
              }
              .image-badge {
                  border-radius: 20px;
                  background-color: $accent-color;
                  padding: 4px 10px;
                  position: relative;
                  display: flex;
                  width: fit-content;
                  justify-content: center;
                  align-items: center;
                  strong {
                      margin-left: 4px;
                  }
                  .image {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 30px;
                      height: 30px;
                      border-radius: 20px;
                      margin-right: 3px;
                      background-color: $white-color;
                      overflow: hidden;
                       img {
                           width: 30px;
                       }
                  }
              }
          }
      }
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    margin: 10px auto 0 auto;
    box-sizing: border-box;

    .swiper-slide {
        background-size: cover;
        background-position: center;
        width: 25%;
        height: 100%;
        opacity: 0.7;
        
        img {
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .swiper-slide-thumb-active {
        opacity: 1;
    }
  }

  .explore-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    @media(max-width: 992px) {

        grid-template-columns: repeat(2, 1fr);
    }

    .item-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;

        @media(max-width: 992px) {

            grid-column: 1 / 3;
            grid-row: 1 / 3;
            
        }
    }

    .item-2 {
        grid-column: 3;
        grid-row: 1 / 3;

        @media(max-width: 992px) {

            grid-column: 1;
            grid-row: 3 / 5;
        }
    }

    .item-3 {
        grid-column: 4;
        grid-row: 1;

        @media(max-width: 992px) {

            grid-column: 2;
            grid-row: 3 / 4;
        }
    }

    .item-4 {
        grid-column: 4;
        grid-row: 2;

        @media(max-width: 992px) {

            grid-column: 2;
            grid-row: 4 / 5;
        }
    }
  }
`



function GlobalStyle() {
  const { theme } = useTheme()

  return <StyledGlobalStyle isDarkMode={theme === 'dark'} />
}

export default GlobalStyle