import { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import logo from '../../../assets/logo.png';
import { GridBoardForm, HomeLogo, LogSection } from '../../../styles/atoms';
import HelpButton from '../../../composants/public/widgets/helpButton';

function UserUpdate() {
    document.title = `BookEasily | Modifiez vos informations`;

    const user = JSON.parse(localStorage.getItem("user") || '{}');
    const token = JSON.parse(localStorage.getItem("token") || '{}');
    const x_xsrf_token = localStorage.getItem("cookies");

    const { param_id } = useParams();

    const [lastName, setLastName] = useState(user.last_name || '');
    const [firstName, setFirstName] = useState(user.first_name || '');
    const [phone, setPhone] = useState(user.phone || '');
    const [country, setCountry] = useState(user.country_id || '');
    const [address, setAddress] = useState(user.address || '');
    const [email, setEmail] = useState(user.email || '');
    const [photo, setPhoto] = useState(null);
    const [role, setRole] = useState(user.role_id || '');
    const [status, setStatus] = useState('');
    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (role === 2) setRole('Client');
        if (role === 3) setRole('Partenaire');
        if (country === 1) setCountry('Cameroun');
    }, [role, country]);

    const forms = new FormData();
    lastName !== user.last_name && forms.append('last_name', lastName);
    firstName !== user.first_name && forms.append('first_name', firstName);
    country && forms.append('country', country);
    phone !== user.phone && forms.append('phone', phone);
    email !== user.email && forms.append('email', email);
    address !== user.address && forms.append('address', address);
    if (photo) forms.append('photo', photo[0]);

    const apiRequest = async (url, options) => {
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data?.status === 1) return data;
            throw new Error('Error response status');
        } catch (err) {
            console.error(err.message);
            throw err;
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setLoad(true);

        try {
            const data = await apiRequest(`https://api.book-easily.com/api/users/${param_id}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'X-XSRF-TOKEN': x_xsrf_token,
                },
                body: forms,
            });

            setStatus(data?.status);
            if (data?.status === 1) {
                const xsrf_token = document.cookie;
                localStorage.setItem("cookies", JSON.stringify(xsrf_token.split('=')[1]));
                localStorage.setItem("user", JSON.stringify(data?.data));
                window.location.assign("/dashboard/user");
            }
        } catch (err) {
            setStatus(0);
        } finally {
            setLoad(false);
        }
    };

    const handlePhotoChange = (e) => {
        const fileInput = e.target.files;
        setPhoto(fileInput);
    };

    return (
        <Fragment>
            <LogSection>
                <form className="my__form g-2 p-3 my-5 col-md-8" method="POST" onSubmit={handleRegister}>
                    <div className="col-md-12 text-center">
                        <Link to="/dashboard">
                            <HomeLogo src={logo} alt="logo BookEasily" />
                        </Link>
                    </div>
                    {status === 1 && (
                        <div className="alert alert-info" role="alert">
                            Modifications effectuées avec succès ! Voir votre <Link to='/dashboard'>tableau de bord</Link>.
                        </div>
                    )}
                    {status === 0 && (
                        <div className="alert alert-danger" role="alert">
                            Vérifiez à nouveau vos informations.
                        </div>
                    )}
                    <div className="col-12 my-1">
                        <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/dashboard" className='text-warning'>Accueil</Link>
                    </div>
                    <GridBoardForm>
                        <div className="col-12 my-1">
                            <input
                                type="text"
                                className="form-control rounded-4"
                                id="last_name"
                                name="last_name"
                                placeholder="Entrez votre nom..."
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <small className="text-muted">Nom</small>
                        </div>
                        <div className="col-12 my-1">
                            <input
                                type="text"
                                className="form-control rounded-4"
                                id="first_name"
                                name="first_name"
                                placeholder="Entrez votre prénom..."
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <small className="text-muted">Prénom</small>
                        </div>
                        <div className="col-12 my-1">
                            <input
                                type="email"
                                className="form-control rounded-4"
                                id="email"
                                name="email"
                                placeholder="Entrez votre email..."
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled
                            />
                            <small className="text-muted">Email</small>
                        </div>
                        <div className="col-12 my-1">
                            <select className="form-select rounded-4" id="role" name="role" disabled>
                                <option value="">Type de compte</option>
                                <option value='client'>Client</option>
                                <option value='admin'>Partenaire</option>
                            </select>
                            <small className="text-muted">Type de compte : {role}</small>
                        </div>
                    </GridBoardForm>
                    <div className="col-12 my-1">
                        <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, image/webp, image/tiff, image/svg"
                            className="form-control rounded-4"
                            id="photo"
                            name="photo"
                            placeholder="Entrez une photo de profil..."
                            onChange={handlePhotoChange}
                        />
                        <small className="text-warning">Photo de profil</small>
                    </div>
                    <GridBoardForm>
                        <div className="col-12 my-1">
                            <input
                                type="text"
                                className="form-control rounded-4"
                                id="country"
                                name="country"
                                value={country}
                                placeholder="Entrez le nom de votre pays de résidence..."
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <small className="text-muted">Pays</small>
                        </div>
                        <div className="col-12 my-1">
                            <input
                                type="text"
                                className="form-control rounded-4"
                                id="address"
                                name="address"
                                value={address}
                                placeholder="Entrez votre adresse de résidence..."
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <small className="text-muted">Adresse</small>
                        </div>
                    </GridBoardForm>
                    <div className="col-12 my-1">
                        <PhoneInput
                            id="phone"
                            name="phone"
                            className="form-control rounded-4"
                            placeholder="Sélectionner l'indicatif de votre pays et saisir votre numéro..."
                            value={phone}
                            onChange={setPhone}
                        />
                        <small className="text-muted">Numéro de téléphone</small>
                    </div>
                    <div className="col-12 text-end my-3">
                        {load ? (
                            <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-4" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En Cours...
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-outline-warning fw-bold w-100 rounded-4">
                                {status === 1 ? <span>Opération réussie ✔</span> : <span>Mettre à jour</span>}
                            </button>
                        )}
                    </div>
                </form>
            </LogSection>
            <HelpButton />
        </Fragment>
    );
}

export default UserUpdate;
