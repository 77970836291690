import React from "react";
import { CatLink } from "../../../styles/atoms";
import ReadMoreDescription from "../widgets/readMoreDescription";

// Utilisation de React.memo pour éviter les rendus inutiles si les props ne changent pas
const CardTowns = React.memo(({ id, cover, town, count, description }) => {
    
    // Gestion des erreurs d'image : Affichage d'une image par défaut en cas d'erreur
    const handleImageError = (e) => {
        e.target.style.backgroundImage = "url('https://api.book-easily.com/storage/assets/NoImage.jpg')";
    };

    return (
        <CatLink to={`/properties/towns/${town}/${id}`} key={`${id}-${town}`}>
                    <div 
                        className="text-bg-dark rounded-4 position-relative" 
                        style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${cover})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            height: '300px',
                        }}
                        onError={handleImageError} // Gestion des erreurs d'image
                    >
                        <div className="position-absolute bottom-0 start-0 p-3">
                            <h4 className="lead-5 fw-bold">
                                {town}
                                {count > 0 ? (
                                    <span className="card-text lead-1"> (+ {count} annonces)</span>
                                ) : (
                                    <span className="card-text lead-1"> (à venir)</span>
                                )}
                            </h4>
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <ReadMoreDescription text={description} maxLength={70} className="card-text lead-1 text-white"/>
                                </li>
                            </ul>
                        </div>
                    </div>
        </CatLink>
    );
});

export default CardTowns;
