import { Fragment, useContext, useMemo } from 'react';
import { Link, Form } from 'react-router-dom';
import img from '../../assets/user.png';
import logo from '../../assets/logo.png';
import { ThemeContext } from '../../context';
import { HeaderContainer, HomeLogo, LogoutButton, MobileSearch, NavLink, SearchForm, StyledLink, UserProfil } from '../../styles/atoms';

function Admin() {
    const { theme } = useContext(ThemeContext);

    // Utilisation de useMemo pour éviter le recalcul répétitif
    const user = useMemo(() => {
        const userData = localStorage.getItem("user");
        return userData ? JSON.parse(userData) : null;
    }, []);

    const token = useMemo(() => {
        const tokenData = localStorage.getItem("token");
        return tokenData ? JSON.parse(tokenData) : null;
    }, []);

    const x_xsrf_token = useMemo(() => localStorage.getItem("cookies"), []);

    const logout = async () => {
        if (!token) return;

        try {
            const response = await fetch('https://api.book-easily.com/api/logout', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-XSRF-TOKEN': x_xsrf_token,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to logout');
            }

            localStorage.clear();
            window.location.assign('/');
        } catch (err) {
            console.error('Logout error:', err.message);
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    };

    return (
        <HeaderContainer theme={theme}>
            <nav className="navbar fixed-top bg-white border-light border-bottom navbar-expand-lg">
                <div className="container-fluid px-md-5">
                    <Link to="/">
                        <HomeLogo src={logo} alt="logo BookEasily" />
                    </Link>

                    <MobileSearch className='rounded-4' method='GET' action="/results/q=" role='search'>
                        <input
                            className="form-control justify-content-center rounded-4"
                            type="search"
                            name='text'
                            id='text'
                            placeholder="🔍 Chercher..."
                            aria-label="Search"
                        />
                    </MobileSearch>

                    <div className="d-flex justify-content-center">
                        <SearchForm method='GET' action="/results/q=" className='mx-2' role='search'>
                            <input
                                className="form-control justify-content-center rounded-4"
                                type="search"
                                name='text'
                                id='text'
                                placeholder="🔍 Chercher..."
                                aria-label="Search"
                            />
                        </SearchForm>
                        <div className="btn-group bg-white justify-content-start border rounded-4">
                            <button
                                type="button"
                                className="btn dropdown-toggle fw-bold"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {user ? (
                                    <StyledLink className='text-capitalize' $theme={theme}>
                                        <UserProfil
                                            src={user.photo_url || img}
                                            alt={user.last_name || 'User'}
                                        />
                                    </StyledLink>
                                ) : (
                                    <StyledLink $theme={theme} className='text-capitalize'>
                                        <i className="bi bi-person-circle text-warning"></i> <span>Compte</span>
                                    </StyledLink>
                                )}
                            </button>
                            <div>
                                {user ? (
                                    <ul className="dropdown-menu">
                                        {user.email_verified === 1 && (
                                            <Fragment>
                                                <li className="dropdown-item">
                                                    <NavLink to="/dashboard/user" $theme={theme}>Profil</NavLink>
                                                </li>
                                                <li className="dropdown-item">
                                                    <NavLink to="/dashboard" $theme={theme}>Statistiques</NavLink>
                                                </li>
                                                <li className="dropdown-item">
                                                    <NavLink to="/dashboard/categories/hotels/new_ads" $theme={theme}>Hôtels +</NavLink>
                                                </li>
                                                <li className="dropdown-item">
                                                    <NavLink to="/dashboard/categories/meubles/new_ads" $theme={theme}>Meublés +</NavLink>
                                                </li>
                                                <li className="dropdown-item">
                                                    <NavLink to="/dashboard/categories/non_meubles/new_ads">Non Meublés +</NavLink>
                                                </li>
                                                <hr />
                                            </Fragment>
                                        )}
                                        <li className="dropdown-item">
                                            <Form method='POST' onSubmit={handleLogout}>
                                                <LogoutButton type='submit' theme={theme}>Déconnexion</LogoutButton>
                                            </Form>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item">
                                            <NavLink to="/sign-in" $theme={theme}>Se connecter</NavLink>
                                        </li>
                                        <li className="dropdown-item">
                                            <NavLink to="/sign-up" $theme={theme}>S'inscrire</NavLink>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </HeaderContainer>
    );
}

export default Admin;
