import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "../hooks";
import Header from "../composants/public/sections/header";
import { HeroSection, MainContainer } from "../styles/atoms";
import Footer from "../composants/public/sections/footer";
import MobileBottomHeader from "../composants/public/sections/mobileBottomHeader";

function EmailVerifyHash() {
    const { theme } = useTheme();
    document.title = 'BookEasily | Confirmez votre email';

    const { param_id } = useParams();
    const token = JSON.parse(localStorage.getItem("token")) || null;

    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Safely set meta description
        const desc = document.querySelector('meta[name="description"]');
        if (desc) {
            desc.setAttribute('content', 'BookEasily | Devenez partenaire et boostez votre visibilité et vos réservations');
        }
    }, []);

    // Extract path from URL
    const path = window.location.pathname.split('/')[6] || '';

    const handleEmailVerifyHash = async (e, param_id, path) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`https://api.book-easily.com/api/email/verify/${param_id}/${path}`, {
                method: 'GET',
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                },
            });

            const data = await response.json();
            setStatus(data?.status || 0);
            
            if (data?.status === 1) {
                window.location.assign("/sign-in");
            } else {
                window.location.assign("/email/verification-notification");
            }
        } catch (error) {
            console.error('Email verification error:', error);
            setStatus(0);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <HeroSection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Félicitations ! 🎉</h2>
                        <div className="col-lg-8 mx-auto">
                            <p className="lead mb-4">Cliquez pour aller sur votre espace personnel.</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                {loading ? (
                                    <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-4" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                    </button>
                                ) : (
                                    <button type="button" onClick={(e) => handleEmailVerifyHash(e, param_id, path)} className="btn btn-warning fw-bold px-4 rounded-4">
                                        {status === 1 ? <span>Bienvenue sur BookEasily ! 🎉</span> : <span>Cliquez pour ouvrir votre tableau de bord !</span>}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </HeroSection>
            </MainContainer>
            <Footer />
            <MobileBottomHeader />
        </Fragment>
    );
}

export default EmailVerifyHash;
