import React, { useEffect } from 'react';
import { useTheme } from '../../../hooks';
import Header from '../../../composants/public/sections/header';
import { MainContainer } from '../../../styles/atoms';
import Footer from '../../../composants/public/sections/footer';
import Faqs from '../../../composants/public/sections/faqs';
import Newsletter from '../../../composants/public/sections/newsletter';
import Hero from '../../../composants/public/sections/hero';
import ScrollButton from '../../../composants/public/widgets/scrollUp';
import MobileBottomHeader from '../../../composants/public/sections/mobileBottomHeader';
import Explore from '../../../composants/public/sections/explore';

function About() {
  const { theme } = useTheme();

  // Set document title using useEffect
  useEffect(() => {
    document.title = 'BookEasily - Comment ça marche ?';
  }, []);

  return (
    <>
      {/* Header Section */}
      <Header />

      <MainContainer theme={theme}>
        {/* Hero Section */}
        <Hero />

        {/* Help Section with Categories and Contact Form */}
        <div className='container pt-4'>
          <div className="row row-cols-1 row-cols-md-2 align-items-center py-2 order-last">
            <form className="g-2 p-3">
              <h2 className='fw-bold lead'>Laissez-nous un message !</h2>
              <div className="col-sm-12 my-4">
                <label htmlFor="name" className="form-label visually-hidden">Nom</label>
                <input type="text" className="form-control rounded-4" id="name" name="name" placeholder="votre nom" />
              </div>
              <div className="col-sm-12 my-4">
                <label htmlFor="email" className="form-label visually-hidden">Email *</label>
                <input type="email" className="form-control rounded-4" id="email" name="email" placeholder="nom@example.com" required />
              </div>
              <div className="my-4">
                <label htmlFor="message" className="form-label visually-hidden">Message *</label>
                <textarea className="form-control rounded-4" id="message" name="message" rows="5" placeholder='Votre message' required></textarea>
              </div>
              <div className="col-12" id="contact">
                <button type="submit" className="btn w-100 btn-dark fw-bold me-2 rounded-4">
                  <i className="bi bi-send-check text-white"></i> Envoyer
                </button>
              </div>
            </form>
            <div className="order-first">
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3978.427382839381!2d9.700256!3d4.0492569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10610d6b10c3e09b%3A0x3f63c7ab9b362c63!2sAkwa%2C%20Douala%2C%20Cameroun!5e0!3m2!1sfr!2sfr!4v1699876543210!5m2!1sfr!2sfr"
                  width="100%"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title='Map BookEasily'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Explore />
        <Faqs />
        <Newsletter />
      </MainContainer>

      {/* Footer Section */}
      <Footer />
      <ScrollButton />
      <MobileBottomHeader />
    </>
  );
}

export default About;
