import { Link } from 'react-router-dom';
import { useTheme } from '../../../hooks';
import { HeroSection } from '../../../styles/atoms';

function Hero() {
    const { theme } = useTheme()
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

    return (
        <HeroSection theme = {theme}>
            <div className="py-5 text-center my-5">
                    <h1 className='text-white'><span className='text-warning'>BookEasily</span> : réserver devient plus facile et rapide !</h1>
                    <p className='text-white m-auto w-75 py-3'>
                        BookEasily est l'interface numérique de réservations d'hotels, meublés et locations
                         en ligne disponible sur l'ensemble du territoire Camerounais et en Afrique.
                    </p>
                    <div>
                        { user ?  
                            user.role_id === 3 ?  
                            <Link to='/dashboard' role="button" className="btn btn-outline-warning px-4 fw-bold rounded-4">Créez votre annonce !</Link>
                            :
                            <Link to='/partners' role="button" className="btn btn-warning px-4 fw-bold rounded-4">Créez votre annonce !</Link>
                        :  
                        <Link to='/partners' role="button" className="btn btn-warning px-4 fw-bold rounded-4">Créez votre annonce !</Link>
                         }
                    </div>
            </div>
        </HeroSection>
    );
  }
  
  export default Hero
  